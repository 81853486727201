.cart-modal {
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0px;
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 10001;
  overflow-y: auto;
}
.cart-modal .cart-inner-block {
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.3);
  bottom: auto;
  display: inline-block;
  left: 50%;
  top: 50%;
  outline: none;
  overflow: hidden;
  position: absolute;
  right: auto;
  transform: translate(-50%, -50%);
}
.ItemVariantModal {
  display: flex;
  background: rgb(244, 246, 251);
  height: auto;
  flex-direction: column;
  border-radius: 12px 12px 0px 0px;
}
.hRwjvg {
  display: flex;
  flex-direction: column;
  padding: 25px;
  min-width: 500px;
}
.hRwjvg .cart-heading h2 {
  color: var(--body-txt-clr);
  font-family: var(--raleway);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  display: block;
  text-align: left;
  position: relative;
}
.hRwjvg .cart-heading i {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  color: #f36a21;
}
.cart-table {
  margin-top: 1em;
}
.cart-table tr td:first-child {
  width: 20%;
}
.hRwjvg img {
  padding: 0px;
}
.cart-table tr {
  background-color: #fff;
}
@media screen and (max-width: 567px) {
  .hRwjvg {
    min-width: 400px;
  }
}
