.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgb(255 255 255 / 95%);
    z-index: 9999;
}

.spinner {
    width: 100px;
    height: 100px;
    border: 8px solid;
    border-color: #f36a21 transparent #fac90a transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}